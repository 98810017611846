import React, { useContext } from "react";
import { Link } from "react-router-dom";

//contexto
import { AuthContext } from "../firebase";

//styles
import "../App.css";

//images
import OpenNew from "../assets/statics/openNew.svg";

export default function ListaProductos() {
  const { productosDesdeDB, handleBorrarProducto } = useContext(AuthContext);

  return (
    <div className="containerComponentes">
      <header className="headerTituloLista">
        <h2 className="tituloSeccion">Lista de productos publicados</h2>{" "}
        <Link className="nuevoProductoBtn" to="/productos">
          Agregar nuevo producto
        </Link>
      </header>
      <div className="containerLista">
        <div className="titulosColumnas" style={{ gridTemplateColumns: "15px 0.15fr 0.7fr 0.7fr 1fr 0.3fr 0.3fr 50px 50px" }}>
          <p className="txtTitulo"></p>
          <p className="txtTitulo">Imágen</p>
          <p className="txtTitulo">Título</p>
          <p className="txtTitulo">Categoría</p>
          <p className="txtTitulo">Descripción</p>
          <p className="txtTitulo" style={{ textAlign: "center" }}>
            Destacado
          </p>
          <p className="txtTitulo">Link PDF</p>
          <p className="txtTitulo">Borrar</p>
          <p className="txtTitulo">Editar</p>
        </div>
        {productosDesdeDB.map((item, index) => (
          <div className="filaLista" key={index} style={{ gridTemplateColumns: "15px 0.15fr 0.7fr 0.7fr 1fr 0.3fr 0.3fr 50px 50px" }}>
            <p className="txtLista">{index + 1}</p>
            {item.imgSrc !== "" ? <img src={item.imgSrc} alt="imagen" className="imgLista" /> : <p style={{ textAlign: "center" }}>✖️</p>}
            <h2 className="tituloProductoLista">{item.nombreProducto}</h2>
            <p className="txtLista">{item.categoriaProducto}</p>
            <p className="txtLista">{item.descripcionProducto}</p>
            {/* <p className="tituloProductoLista">{item.fechaCreacion.toDate()}</p> */}
            {item.destacado ? (
              <p className="txtLista" style={{ textAlign: "center" }}>
                ✓
              </p>
            ) : (
              <p className="txtLista" style={{ textAlign: "center" }}>
                ✗
              </p>
            )}
            {item.linkFichaProducto === "" ? (
              <p className="txtLista linkPdf" title="Sin PDF asociado" style={{ pointerEvents: "none", opacity: "0.3", backgroundColor: "#1c1c1c" }}>
                SIN PDF
              </p>
            ) : (
              <a href={item.linkFichaProducto} target="_blank" rel="noreferrer" className="txtLista linkPdf" title="Abrir en nueva ventana">
                PDF <img src={OpenNew} alt="open" className="logoOpen" />
              </a>
            )}

            <button className="txtLista borrarbtn" title="Eliminar producto" onClick={handleBorrarProducto} name={`${index}`} />
            <Link to={`/editarproducto?index=${index}`} className="txtLista editbtn" title="Editar producto" name={`${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
