import React, { useState, useEffect, useContext } from "react";

import { Link, useSearchParams, useNavigate } from "react-router-dom";

//uuid package
import { v4 } from "uuid";

//firebase
import { storage, firestoreDb } from "../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { Timestamp, doc, setDoc } from "firebase/firestore";

//images
import FileUpload from "../assets/statics/uploadFile.svg";

//contexto
import { AuthContext } from "../firebase";

//styles
import "../App.css";

export default function NovedadesEditar() {
  const { novedadesDesdeDB, queryForListasNovedades } = useContext(AuthContext);
  const navigate = useNavigate();

  //set variable that will be add to FB DataBase
  const [novedadEditable, setNovedadEditable] = useState({});
  const [pathNovedad, setPathNovedad] = useState("");
  const [ubicacionImgVieja, setUbicacionImgVieja] = useState("");

  //state to know if the user has change the files when "guardar" function its called.
  const [imgChanged, setImgChanged] = useState(false);

  //state for btn borrarContenido
  const [disableBtnBorrarImg, setDisableBtnBorrarImg] = useState(true);

  //get info from URL
  const [searchParams, setSearchParams] = useSearchParams("");
  let index = searchParams.get("index");

  useEffect(() => {
    console.log(novedadesDesdeDB[index]);
    if (novedadesDesdeDB[index] !== undefined) {
      //set initial variables from DB
      setNovedadEditable({
        fechaNovedad: novedadesDesdeDB[index].fechaNovedad,
        tituloNovedad: novedadesDesdeDB[index].tituloNovedad,
        comentario: novedadesDesdeDB[index].comentario,
        descripcionNovedad: novedadesDesdeDB[index].descripcionNovedad,
        textoCompleto: novedadesDesdeDB[index].textoCompleto,
        imgSrc: novedadesDesdeDB[index].imgSrc,
        fechaCreacion: Timestamp.fromDate(new Date()),
        ubicacionImg: novedadesDesdeDB[index].ubicacionImg,
        linkExterno: novedadesDesdeDB[index].linkExterno,
      });

      //Set old variables to have info to modify or delete them
      setPathNovedad(novedadesDesdeDB[index].path);
      setUbicacionImgVieja(novedadesDesdeDB[index].ubicacionImg);

      //get labels from de pdf and png files of the DB
      if (novedadesDesdeDB[index].ubicacionImg !== "") {
        setDisableBtnBorrarImg(false);
        const imgname = novedadesDesdeDB[index].ubicacionImg.split("/");
        const imgname2 = imgname[1].split("jpg");
        setLabelFileUpload(imgname2[0] + "jpg ");
      }
    }
  }, [novedadesDesdeDB]);

  //btn states
  const [labelBtnGuardar, setLabelBtnGuardar] = useState("Guardar");
  const [btnDisabled, setBtnDisabled] = useState(true);

  //characters counters
  const [counterCharName, setCounterCharName] = useState(0);
  const [counterCharDesc, setCounterCharDesc] = useState(0);

  //file state
  const [imageUpload, setImageUpload] = useState(null);
  const [labelFileUpload, setLabelFileUpload] = useState("Seleccionar archivo (*.jpg)");

  //Principal FUNCTION
  function guardarData(e) {
    e.preventDefault();
    setLabelBtnGuardar("Guardando...");
    if (imgChanged) {
      console.log("guardar desde img");
      uploadImage();
    } else {
      uploadNovedadData();
      console.log("guardar solo data");
    }
  }

  //delete old files
  async function borrarImgVieja() {
    const imgRef = ref(storage, `${ubicacionImgVieja}`);
    if (imgRef.parent !== null) {
      console.log(imgRef);
      await deleteObject(imgRef)
        .then(console.log("img vieja borrada"))
        .catch((err) => console.log(err));
    }
  }

  //function upload
  const uploadImage = () => {
    if (imageUpload == null) return;
    setBtnDisabled(true);
    document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
    const imageRef = ref(storage, `novedades-imagenes/${imageUpload.name + v4()}`);
    console.log(imageRef._location.path_);
    novedadEditable.ubicacionImg = imageRef._location.path_;
    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        borrarImgVieja();
        getDownloadURL(snapshot.ref).then((url) => {
          novedadEditable.imgSrc = url;
          console.log("imagen subida");
          setImgChanged(false);
          uploadNovedadData();
        });
      })
      .catch((err) => {
        console.log(err);
        console.log("error guardando imagen");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
        }, 1000);
      });
  };

  //upload to DataBase
  const uploadNovedadData = async () => {
    document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
    const docModificable = doc(firestoreDb, `novedades/${pathNovedad}`);
    const novedadRef = await setDoc(docModificable, novedadEditable)
      .then(
        console.log("guardado en DB"),
        console.log(novedadEditable),
        setLabelBtnGuardar("Guardado ✓"),
        queryForListasNovedades(),
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(true);
          navigate("/listanovedades");
        }, 2000)
      )
      .catch((err) => {
        console.log(err);
        console.log("error guardando en Firestore");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(false);
        }, 1000);
      });
  };

  //function to set image data after select file
  function completeImageData(e) {
    console.log(e.target.files);
    if (e.target.files.length !== 0) {
      setDisableBtnBorrarImg(false);
      setBtnDisabled(false);
      setImgChanged(true);
      setImageUpload(e.target.files[0]);
      setLabelFileUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
    }
  }

  //function to set txt
  function completarTexto(e) {
    setBtnDisabled(false);
    novedadEditable.textoCompleto = e.target.value;
  }

  //functions counters and complete Name and Description
  function countCharName(e) {
    setBtnDisabled(false);
    let string = e.target.value;
    setCounterCharName(string.length);
    novedadEditable.tituloNovedad = e.target.value;
  }
  function countCharDesc(e) {
    setBtnDisabled(false);
    let string = e.target.value;
    setCounterCharDesc(string.length);
    novedadEditable.descripcionNovedad = e.target.value;
  }

  return (
    <div className="containerComponentes">
      <h2 className="tituloSeccion">Publicar nueva novedad</h2>
      <p className="alertTxt">Atención! Completar todos los campos obligatorios para poder guardar la publicación.</p>
      <br />

      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
        }}
        id="formCargaNovedad"
      >
        <p className="titleInput">Título novedad</p>
        <input type="text" className="inputTxt" maxLength={100} onChange={countCharName} defaultValue={novedadEditable.tituloNovedad} />
        <p className="counters">{counterCharName}/100</p>
        <p className="titleInput">Descripción novedad</p>
        <textarea
          type="text"
          className="inputTxt areaTxt"
          maxLength={200}
          onChange={countCharDesc}
          style={{ height: "72px" }}
          defaultValue={novedadEditable.descripcionNovedad}
        />
        <p className="counters">{counterCharDesc}/200</p>

        <p className="titleInput">Fecha novedad</p>
        <input
          type="text"
          className="inputTxt"
          maxLength={100}
          onChange={(e) => {
            novedadEditable.fechaNovedad = e.target.value;
            setBtnDisabled(false);
          }}
          placeholder="Ejemplo: 22 de abril de 2022"
          defaultValue={novedadEditable.fechaNovedad}
        />
        <p className="titleInput">Imágen Novedad</p>
        <label htmlFor="inputImg" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input type="file" id="inputImg" style={{ display: "none" }} maxLength={48} accept="image/jpeg, image/jpg" onChange={completeImageData} />
          <span id="imgName">{labelFileUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarImg && "classDisabled"}`}
            onClick={() => {
              setImageUpload(null);
              setLabelFileUpload("Seleccionar archivo (*.jpg)");
              setBtnDisabled(true);
              setDisableBtnBorrarImg(true);
              setImgChanged(true);
            }}
          >
            ✕
          </button>
        </label>
        <p className="titleInput">Texto completo nota (opcional)</p>
        <textarea
          type="text"
          className="inputTxt areaTxt"
          style={{ height: "400px" }}
          onChange={completarTexto}
          defaultValue={novedadEditable.textoCompleto}
        />
        <p className="titleInput">Comentario</p>
        <input
          type="text"
          className="inputTxt"
          onChange={(e) => {
            novedadEditable.comentario = e.target.value;
            setBtnDisabled(false);
          }}
          placeholder="Ejemplo: Link Externo ó Nota completa"
          defaultValue={novedadEditable.comentario}
        />
        <p className="titleInput">Link Externo</p>
        <input
          type="text"
          className="inputTxt"
          onChange={(e) => {
            novedadEditable.linkExterno = e.target.value;
            setBtnDisabled(false);
          }}
          defaultValue={novedadEditable.linkExterno}
        />

        <span className="containerBtn">
          <Link to="/listanovedades" className="btnSubmit colorSec">
            Cancelar
          </Link>
          <button className="btnSubmit colorPpal " type="submit" onClick={guardarData} disabled={btnDisabled}>
            {labelBtnGuardar}
          </button>
        </span>
      </form>
    </div>
  );
}
