import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//contexto
import { AuthContext } from "../firebase";

//uuid package
import { v4 } from "uuid";

//firebase
import { storage, firestoreDb } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Timestamp, addDoc, collection } from "firebase/firestore";

//images
import FileUpload from "../assets/statics/uploadFile.svg";

//styles
import "../App.css";

export default function Novedades() {
  const { queryForListasNovedades } = useContext(AuthContext);

  //set variable that will be add to FB DataBase
  const [novedadDB, setNovedadDB] = useState({});

  useEffect(() => {
    setNovedadDB({
      tituloNovedad: "",
      descripcionNovedad: "",
      linkExterno: "",
      imgSrc: "",
      textoCompleto: "",
      fechaCreacion: Timestamp.fromDate(new Date()),
      fechaNovedad: "",
      ubicacionImg: "",
      comentario: "",
    });
  }, []);

  //btn states
  const [labelBtnGuardar, setLabelBtnGuardar] = useState("Guardar");
  const [btnDisabled, setBtnDisabled] = useState(true);

  //characters counters
  const [counterCharName, setCounterCharName] = useState(0);
  const [counterCharDesc, setCounterCharDesc] = useState(0);

  //file state
  const [imageUpload, setImageUpload] = useState(null);
  const [labelFileUpload, setLabelFileUpload] = useState("Seleccionar archivo (*.jpg)");

  //state for btn borrarContenido
  const [disableBtnBorrarImg, setDisableBtnBorrarImg] = useState(true);

  //Principal FUNCTION
  function guardarData(e) {
    e.preventDefault();
    setLabelBtnGuardar("Guardando...");
    uploadImage();
  }

  //function upload
  const uploadImage = () => {
    if (imageUpload == null) return;
    setBtnDisabled(true);
    document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
    const imageRef = ref(storage, `novedades-imagenes/${imageUpload.name + v4()}`);
    console.log(imageRef._location.path_);
    novedadDB.ubicacionImg = imageRef._location.path_;
    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          novedadDB.imgSrc = url;
          console.log("imagen subida");
          uploadProductData();
        });
      })
      .catch((err) => {
        console.log(err);
        console.log("error guardando imagen");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(false);
        }, 1000);
      });
  };

  //upload to DataBase
  const uploadProductData = async () => {
    const novedadRef = await addDoc(collection(firestoreDb, "novedades"), novedadDB)
      .then(
        console.log("guardado en DB"),
        console.log(novedadDB),
        setLabelBtnGuardar("Guardado ✓"),
        queryForListasNovedades(),
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          document.getElementById("formCargaNovedad").reset();
          setImageUpload(null);
          setLabelFileUpload("Seleccionar archivo (*.png)");
          setBtnDisabled(true);
          setNovedadDB({
            tituloNovedad: "",
            descripcionNovedad: "",
            linkExterno: "",
            imgSrc: "",
            textoCompleto: "",
            fechaCreacion: Timestamp.fromDate(new Date()),
            fechaNovedad: "",
            ubicacionImg: "",
            comentario: "",
          });
        }, 2000)
      )
      .catch((err) => {
        console.log(err);
        console.log("error guardando en Firestore");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(false);
        }, 1000);
      });
  };

  //Enable "Guardar" btn only if every field is complete
  document.addEventListener("keyup", enableBtn);
  document.addEventListener("change", enableBtn);
  function enableBtn(e) {
    e.preventDefault();
    if (novedadDB.tituloNovedad !== "" && novedadDB.descripcionNovedad !== "" && novedadDB.fechaNovedad !== "" && imageUpload !== null) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  //function to set image data after select file
  function completeImageData(e) {
    if (e.target.files.length !== 0) {
      setDisableBtnBorrarImg(false);
      setImageUpload(e.target.files[0]);
      setLabelFileUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
    }
  }

  //function to set txt
  function completarTexto(e) {
    novedadDB.textoCompleto = e.target.value;
  }

  //functions counters and complete Name and Description
  function countCharName(e) {
    let string = e.target.value;
    setCounterCharName(string.length);
    novedadDB.tituloNovedad = e.target.value;
  }
  function countCharDesc(e) {
    let string = e.target.value;
    setCounterCharDesc(string.length);
    novedadDB.descripcionNovedad = e.target.value;
  }

  return (
    <div className="containerComponentes">
      <h2 className="tituloSeccion">Publicar nueva novedad</h2>
      <p className="alertTxt">Atención! Completar todos los campos obligatorios para poder guardar la publicación.</p>
      <br />

      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
        }}
        id="formCargaNovedad"
      >
        <p className="titleInput">Título novedad</p>
        <input type="text" className="inputTxt" maxLength={100} onChange={countCharName} />
        <p className="counters">{counterCharName}/100</p>
        <p className="titleInput">Descripción novedad</p>
        <textarea type="text" className="inputTxt areaTxt" maxLength={200} onChange={countCharDesc} style={{ height: "72px" }} />
        <p className="counters">{counterCharDesc}/200</p>

        <p className="titleInput">Fecha novedad</p>
        <input
          type="text"
          className="inputTxt"
          maxLength={100}
          onChange={(e) => {
            novedadDB.fechaNovedad = e.target.value;
          }}
          placeholder="Ejemplo: 22 de abril de 2022"
        />
        <p className="titleInput">Imágen Novedad</p>
        <label htmlFor="inputImg" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input type="file" id="inputImg" style={{ display: "none" }} maxLength={48} accept="image/jpg" onChange={completeImageData} />
          <span id="imgName">{labelFileUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarImg && "classDisabled"}`}
            onClick={() => {
              setImageUpload(null);
              setLabelFileUpload("Seleccionar archivo (*.jpg)");
              setBtnDisabled(true);
              setDisableBtnBorrarImg(true);
            }}
          >
            ✕
          </button>
        </label>
        <p className="titleInput">Texto completo nota (opcional)</p>
        <textarea type="text" className="inputTxt areaTxt" style={{ height: "400px" }} onChange={completarTexto} />
        <p className="titleInput">Comentario</p>
        <input
          type="text"
          className="inputTxt"
          onChange={(e) => {
            novedadDB.comentario = e.target.value;
          }}
          placeholder="Ejemplo: Link Externo ó Nota completa"
        />
        <p className="titleInput">Link Externo</p>
        <input
          type="text"
          className="inputTxt"
          onChange={(e) => {
            novedadDB.linkExterno = e.target.value;
          }}
        />

        <span className="containerBtn">
          <Link to="/listanovedades" className="btnSubmit colorSec">
            Cancelar
          </Link>
          <button className="btnSubmit colorPpal " type="submit" onClick={guardarData} disabled={btnDisabled}>
            {labelBtnGuardar}
          </button>
        </span>
      </form>
    </div>
  );
}
