import React, { useContext } from "react";
import { Link } from "react-router-dom";

//styles
import "../App.css";

//contexto
import { AuthContext } from "../firebase";

export default function ListaNovedades() {
  const { novedadesDesdeDB, handleBorrarNovedades } = useContext(AuthContext);

  return (
    <div className="containerComponentes">
      <header className="headerTituloLista">
        <h2 className="tituloSeccion">Lista de novedades publicadas</h2>{" "}
        <Link className="nuevoProductoBtn" to="/novedades">
          Agregar nueva novedad
        </Link>
      </header>
      <div className="containerLista">
        <div className="titulosColumnas" style={{ gridTemplateColumns: "15px 0.2fr 0.9fr 0.6fr 1fr 0.3fr 0.3fr 0.3fr 50px 50px" }}>
          <p className="txtTitulo"></p>
          <p className="txtTitulo">Imágen</p>
          <p className="txtTitulo">Título</p>
          <p className="txtTitulo">Fecha</p>
          <p className="txtTitulo">Descripción</p>
          <p className="txtTitulo">Texto</p>
          <p className="txtTitulo">Comentario</p>
          <p className="txtTitulo" style={{ textAlign: "center" }}>
            Link
          </p>
          <p className="txtTitulo">Borrar</p>
          <p className="txtTitulo">Editar</p>
        </div>
        {novedadesDesdeDB.map((item, index) => (
          <div className="filaLista" key={index} style={{ gridTemplateColumns: "15px 0.2fr 0.9fr 0.6fr 1fr 0.3fr 0.3fr 0.3fr 50px 50px" }}>
            <p className="txtLista">{index + 1}</p>
            <div className="containerImgNov">
              <img src={item.imgSrc} alt="imagen" className="imgLista" />
            </div>
            <h2 className="tituloProductoLista">{item.tituloNovedad}</h2>
            <p className="txtLista">{item.fechaNovedad}</p>
            <p className="txtLista">{item.descripcionNovedad}</p>
            <p className="txtLista" title={`${item.textoCompleto}`}>
              Ver al editar
            </p>

            <p className="txtLista">{item.comentario}</p>
            {item.linkExterno === "" ? (
              <p className="txtLista linkPdf" title="Sin link asociado" style={{ pointerEvents: "none", opacity: "0.3", backgroundColor: "#1c1c1c" }}>
                LINK
              </p>
            ) : (
              <a href={item.linkExterno} className="txtLista linkPdf" target="_blank" rel="noreferrer">
                LINK
              </a>
            )}
            <button className="txtLista borrarbtn" title="Eliminar producto" onClick={handleBorrarNovedades} name={`${index}`} />
            <Link to={`/editarnovedad?index=${index}`} className="txtLista editbtn" title="Editar producto" name={`${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
