import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

//contexto
import { AuthContext } from "../firebase";

//Styles
import "../styles/signin.css";

//images
import logoSignIn from "../assets/statics/logo-signin.svg";

function SignIn() {
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState("");
  const { signIn } = useContext(AuthContext);

  //Funcion para hacer SIGN IN.
  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrorState("");
    let email = document.getElementById("loginEmailInput").value;
    let password = document.getElementById("loginPassInput").value;

    try {
      await signIn(email, password);
      navigate("/", { replace: true });
    } catch (error) {
      setErrorState(error.message);
      console.log(error.message);
      const errorCode = error.code;
      const errorMenssage = error.message;
      // setErrorState("Email ó Password incorrectos");
      if (errorCode === "auth/invalid-email") {
        setErrorState("Ingrese un email válido");
      } else if (errorCode === "auth/user-not-found") {
        setErrorState("Usuario incorrecto");
      } else if (errorCode === "auth/internal-error") {
        setErrorState("Email ó Password incorrecto");
      } else if (errorCode === "auth/wrong-password") {
        setErrorState("Password incorrecto");
      }
      console.log(errorCode, errorMenssage);
    }
  };

  return (
    <>
      <div className="containerLogIn">
        <img src={logoSignIn} alt="logoSVG" className="logoSVG" />
        <br />
        <br />
        <p className="tituloBienvenida">Hola!</p>
        <form type="sumbit" className="formLogIn" id="formLogIn">
          <input type="email" name="mailLogIn" className="inputLogIn" id="loginEmailInput" placeholder="Email address" required />
          <input type="password" name="passLogIn" className="inputLogIn" id="loginPassInput" placeholder="Password" required />
          <div className="subInfoError">{errorState}</div>
        </form>
        <button type="submit" form="formLogIn" className="btnLogIn" id="btnLogueame" onClick={handleSignIn}>
          Ingresar
        </button>
      </div>
    </>
  );
}

export default SignIn;
