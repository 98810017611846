import "./App.css";

//Router Dom
import { Routes, Route } from "react-router-dom";

//Context
import { AuthContextProvider } from "./firebase";

//components
import PageNotFound from "./containers/PageNotFound";
import Principal from "./containers/Principal";
import Productos from "./containers/Productos";
import ListaProductos from "./containers/ListaProductos";
import Novedades from "./containers/Novedades";
import Suscriptores from "./containers/Sucriptores";
import ListaNovedades from "./containers/ListaNovedades";
import Entrar from "./containers/SignIn";
import ProductosEditar from "./containers/ProductosEditar";
import NovedadesEditar from "./containers/NovedadesEditar";

export default function App() {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route exact path="/signin" element={<Entrar to="signin" />} />
          <Route exact path="/" element={<Principal />}>
            <Route exact path="" element={<ListaNovedades to="listanovedades" />} />
            <Route exact path="/productos" element={<Productos to="productos" />} />
            <Route exact path="/listaproductos" element={<ListaProductos to="listaproductos" />} />
            <Route exact path="/novedades" element={<Novedades to="novedades" />} />
            <Route exact path="/listanovedades" element={<ListaNovedades to="listanovedades" />} />
            <Route exact path="/suscriptos" element={<Suscriptores to="suscriptores" />} />
            <Route exact path="/editarproducto" element={<ProductosEditar to="editarproducto" />} />
            <Route exact path="/editarnovedad" element={<NovedadesEditar to="editarnovedad" />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </AuthContextProvider>
    </>
  );
}
