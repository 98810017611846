import React, { useEffect, useState } from "react";

//Firebase
import { firestoreDb } from "../firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";

//styles
import "../App.css";

export default function Sucriptores() {
  const [txtBtnCopiar, setTxtBtnCopiar] = useState("Copiar todos los Emails");
  //Variable for product list
  const [suscriptoresDesdeDB, setSuscriptoresDesdeDB] = useState([]);

  useEffect(() => {
    queryForListas();
  }, []);

  //get list of products from Firebase Firestore DB
  async function queryForListas() {
    const listasRef = collection(firestoreDb, "suscriptores");
    const querySnapshot = await getDocs(listasRef);
    const docsSuscriptores = querySnapshot.docs.map((snap) => {
      return { path: snap.id, ...snap.data() };
    });
    let docOrdenados = docsSuscriptores.sort((a, b) => (a.fechaSuscripcion < b.fechaSuscripcion ? 1 : -1));
    setSuscriptoresDesdeDB(docOrdenados);
  }

  //funtion to delete an item from Firebase Firestore DB
  async function handleBorrar(e) {
    let ubicacionSuscriptor = suscriptoresDesdeDB[e.target.name].path;
    if (window.confirm(`Desea eliminar ${suscriptoresDesdeDB[e.target.name].mailSuscricion}?`) === true) {
      await deleteDoc(doc(firestoreDb, "suscriptores", `${ubicacionSuscriptor}`))
        .then(console.log("exito borrando"))
        .then(queryForListas())
        .catch((err) => console.log(err));
    } else {
      console.log("no eliminar");
    }
  }

  //funtion to get time from Timestamp
  function getTime(fecha) {
    const date = fecha.toDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return day + "/" + month + "/" + year + " - " + hours + ":" + minutes;
  }

  //copy all mails to clipboard
  function handleCopiarMails() {
    var mailLabels = [];
    for (var i = 0; i < suscriptoresDesdeDB.length; i++) {
      var mail = suscriptoresDesdeDB[i].mailSuscricion;
      mailLabels.push(mail);
    }
    navigator.clipboard.writeText(mailLabels.toString());
    setTxtBtnCopiar("Copiados  ✓");
    setTimeout(() => {
      setTxtBtnCopiar("Copiar todos los Emails");
    }, 1000);
  }

  return (
    <div className="containerComponentes">
      <header className="headerTituloLista">
        <h2 className="tituloSeccion">Lista de suscriptos al newsletter</h2>
        <button className="copiarMails" onClick={handleCopiarMails}>
          {txtBtnCopiar}
        </button>
      </header>
      <div className="containerLista">
        <div className="titulosColumnas" style={{ gridTemplateColumns: "15px 0.9fr 0.6fr 50px" }}>
          <p className="txtTitulo"></p>
          <p className="txtTitulo">Email</p>
          <p className="txtTitulo">Fecha suscripción</p>
          <p className="txtTitulo">Borrar</p>
        </div>

        {suscriptoresDesdeDB.map((item, index) => (
          <div className="filaLista" key={index} style={{ gridTemplateColumns: "15px 0.9fr 0.6fr 50px" }}>
            <p className="txtLista">{index + 1}</p>
            <p className="txtLista">{item.mailSuscricion}</p>
            <p className="txtLista">{getTime(item.fechaSuscripcion)}</p>

            <button className="txtLista borrarbtn" title="Eliminar producto" onClick={handleBorrar} name={`${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
