import React, { useContext } from "react";

//Router Dom
import { NavLink, useNavigate } from "react-router-dom";

//styles
import "../styles/header.css";

//contexto
import { AuthContext } from "../firebase";

//images
import Logoheader from "../assets/statics/logo-header.svg";
import logOutIcon from "../assets/statics/logout.svg";

export default function Header() {
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);

  const handleLogOut = async (e) => {
    // e.preventDefault();
    try {
      await logOut();
      navigate("/signin");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="containerHeader">
      <div className="containerNavation">
        <img src={Logoheader} alt="logo header" className="logoHeader" />
        <nav className="navLinks">
          <NavLink to="/listaproductos" className="linkHeader">
            Productos
          </NavLink>
          <NavLink to="/listanovedades" className="linkHeader">
            Novedades
          </NavLink>
          <NavLink to="/suscriptos" className="linkHeader">
            Suscriptos
          </NavLink>
        </nav>
        <button className="logoutBtn" onClick={handleLogOut}>
          SALIR <img src={logOutIcon} className="logOutIcon" />
        </button>
      </div>
    </div>
  );
}
