import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, deleteObject, ref } from "firebase/storage";
import { getFirestore, collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut, getAuth, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDS4Gyx9iDg0Ak1GYXmEbyN6IAilaN4MrA",
  authDomain: "mafralacwebpage.firebaseapp.com",
  databaseURL: "https://mafralacwebpage-default-rtdb.firebaseio.com",
  projectId: "mafralacwebpage",
  storageBucket: "mafralacwebpage.appspot.com",
  messagingSenderId: "733681392679",
  appId: "1:733681392679:web:bbec7bae410b29f1204202",
  measurementId: "G-6HH2GRBTE1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Storage
export const storage = getStorage(app);

//initialize Firestore
export const firestoreDb = getFirestore();

export const auth = getAuth(app);

//AuthContext
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [userUid, setUserUid] = useState("");

  //sign in
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  //logout
  const logOut = () => {
    return signOut(auth);
  };

  //si hay algun cambio, chequea si esta loggeado
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser !== null) {
        setUser(currentUser);
        console.log("LOGGED IN!");
        setUserUid(currentUser.uid);
      } else {
        setUser(currentUser);
        console.log("No user");
        navigate("/signin", { replace: true });
      }
    });
  }, []);

  //Variable for product list
  const [productosDesdeDB, setProductosDesdeDB] = useState([]);
  //get list of products from Firebase Firestore DB
  async function queryForListasProductos() {
    console.log("query Productos");
    const listasRef = collection(firestoreDb, "productos");
    const querySnapshot = await getDocs(listasRef);
    const docsProductos = querySnapshot.docs.map((snap) => {
      return { path: snap.id, ...snap.data() };
    });
    let docOrdenados = docsProductos.sort((a, b) => (a.categoriaProducto > b.categoriaProducto ? 1 : -1));
    setProductosDesdeDB(docOrdenados);
  }
  //   function to delete an item from the list of products
  async function handleBorrarProducto(e) {
    let ubicacionImg = productosDesdeDB[e.target.name].ubicacionImg;
    const imgRef = ref(storage, `${ubicacionImg}`);
    let ubicacionPdf = productosDesdeDB[e.target.name].ubicacionPdf;
    const pdfRef = ref(storage, `${ubicacionPdf}`);
    let ubicacionProducto = productosDesdeDB[e.target.name].path;
    const prodRef = doc(firestoreDb, `productos/${ubicacionProducto}`);
    if (window.confirm("Desea Eliminar?") === true) {
      if (imgRef.parent !== null) {
        console.log(imgRef);
        await deleteObject(imgRef)
          .then(console.log("img borrada"))
          .catch((err) => console.log(err));
      }
      if (pdfRef.parent !== null) {
        console.log(pdfRef);
        await deleteObject(pdfRef)
          .then(console.log("pdf borrado"))
          .catch((err) => console.log(err));
      }
      if (prodRef.parent !== null) {
        console.log(prodRef);
        await deleteDoc(prodRef)
          .then(console.log("exito borrando en DB"))
          .then(queryForListasProductos())
          .catch((err) => console.log(err));
      }
    } else {
      console.log("no borrar");
    }
  }

  //Variable for novedades list
  const [novedadesDesdeDB, setNovedadesDesdeDB] = useState([]);
  //get list of novedades from Firebase Firestore DB
  async function queryForListasNovedades() {
    console.log("query Novedades");
    const listasRef = collection(firestoreDb, "novedades");
    const querySnapshot = await getDocs(listasRef);
    const docsProductos = querySnapshot.docs.map((snap) => {
      return { path: snap.id, ...snap.data() };
    });
    let docOrdenados = docsProductos.sort((a, b) => (a.fechaNovedad > b.fechaNovedad ? 1 : -1));
    setNovedadesDesdeDB(docOrdenados);
  }
  //   function to delete an item from the list of products
  async function handleBorrarNovedades(e) {
    let ubicacionImg = novedadesDesdeDB[e.target.name].ubicacionImg;
    const imgRef = ref(storage, `${ubicacionImg}`);
    let ubicacionNovedad = novedadesDesdeDB[e.target.name].path;
    const novRef = doc(firestoreDb, `novedades/${ubicacionNovedad}`);
    if (window.confirm("Desea Eliminar?") === true) {
      if (imgRef.parent !== null) {
        deleteObject(imgRef).then(console.log("img borrada"));
      }
      if (novRef.parent !== null) {
        await deleteDoc(novRef)
          .then(console.log("exito borrando"))
          .then(queryForListasNovedades())
          .catch((err) => console.log(err));
      }
    } else {
      console.log("no borrar");
    }
  }

  useEffect(() => {
    queryForListasProductos();
    queryForListasNovedades();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        userUid,
        productosDesdeDB,
        novedadesDesdeDB,
        signIn,
        logOut,
        queryForListasProductos,
        queryForListasNovedades,
        handleBorrarProducto,
        handleBorrarNovedades,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
