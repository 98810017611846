import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

//uuid package
import { v4 } from "uuid";

//firebase
import { storage, firestoreDb } from "../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { Timestamp, doc, setDoc } from "firebase/firestore";

//images
import FileUpload from "../assets/statics/uploadFile.svg";

//contexto
import { AuthContext } from "../firebase";

//styles
import "../App.css";

export default function ProductosEditar() {
  const { productosDesdeDB, queryForListasProductos } = useContext(AuthContext);
  const navigate = useNavigate();

  //set variable that will be add to FB DataBase
  const [productoEditable, setProductoEditable] = useState({});
  const [pathProducto, setPathProducto] = useState("");
  const [ubicacionImgVieja, setUbicacionImgVieja] = useState("");
  const [ubicacionPdfViejo, setUbicacionPdfViejo] = useState("");

  //state to know if the user has change the files when "guardar" function its called.
  const [imgChanged, setImgChanged] = useState(false);
  const [pdfChanged, setPdfChanged] = useState(false);

  //state for btn borrarContenido
  const [disableBtnBorrarImg, setDisableBtnBorrarImg] = useState(true);
  const [disableBtnBorrarPdf, setDisableBtnBorrarPdf] = useState(true);

  //get info from URL
  const [searchParams, setSearchParams] = useSearchParams("");
  let index = searchParams.get("index");

  useEffect(() => {
    console.log(productosDesdeDB[index]);
    if (productosDesdeDB[index] !== undefined) {
      //set initial variables from DB
      setProductoEditable({
        categoriaProducto: productosDesdeDB[index].categoriaProducto,
        nombreProducto: productosDesdeDB[index].nombreProducto,
        descripcionProducto: productosDesdeDB[index].descripcionProducto,
        linkFichaProducto: productosDesdeDB[index].linkFichaProducto,
        imgSrc: productosDesdeDB[index].imgSrc,
        destacado: productosDesdeDB[index].destacado,
        fechaCreacion: Timestamp.fromDate(new Date()),
        ubicacionPdf: productosDesdeDB[index].ubicacionPdf,
        ubicacionImg: productosDesdeDB[index].ubicacionImg,
      });

      //Set old variables to have info to modify or delete them
      setPathProducto(productosDesdeDB[index].path);
      setUbicacionImgVieja(productosDesdeDB[index].ubicacionImg);
      setUbicacionPdfViejo(productosDesdeDB[index].ubicacionPdf);

      //get labels from de pdf and png files of the DB
      if (productosDesdeDB[index].ubicacionImg !== "") {
        setDisableBtnBorrarImg(false);
        const imgname = productosDesdeDB[index].ubicacionImg.split("/");
        const imgname2 = imgname[1].split("png");
        setLabelFileUpload(imgname2[0] + "png");
      }
      if (productosDesdeDB[index].ubicacionPdf !== "") {
        setDisableBtnBorrarPdf(false);
        const pdfname = productosDesdeDB[index].ubicacionPdf.split("/");
        const pdfname2 = pdfname[1].split("pdf");
        setLabelPdfUpload(pdfname2[0] + "pdf");
      }
    }
  }, [productosDesdeDB]);

  //btn "Guardar" states
  const [labelBtnGuardar, setLabelBtnGuardar] = useState("Guardar");
  const [btnDisabled, setBtnDisabled] = useState(true);

  //characters counters
  const [counterCharName, setCounterCharName] = useState(0);
  const [counterCharDesc, setCounterCharDesc] = useState(0);

  //file state
  const [imageUpload, setImageUpload] = useState(null);
  const [pdfUpload, setPdfUpload] = useState(null);
  const [labelFileUpload, setLabelFileUpload] = useState("Seleccionar archivo (*.png)");
  const [labelPdfUpload, setLabelPdfUpload] = useState("Seleccionar archivo (*.pdf)");

  //Principal FUNCTION
  function guardarData(e) {
    e.preventDefault();
    setLabelBtnGuardar("Guardando...");
    if (imgChanged) {
      console.log("guardar desde img");
      uploadImage();
    } else if (pdfChanged) {
      console.log("guardar solo pdf");
      uploadPdfFile();
    } else {
      uploadProductData();
      console.log("guardar solo data");
    }
  }

  //delete old files
  async function borrarImgVieja() {
    const imgRef = ref(storage, `${ubicacionImgVieja}`);
    if (imgRef.parent !== null) {
      console.log(imgRef);
      await deleteObject(imgRef)
        .then(console.log("img vieja borrada"))
        .catch((err) => console.log(err));
    }
  }
  async function borrarPdfViejo() {
    const pdfRef = ref(storage, `${ubicacionPdfViejo}`);
    if (pdfRef.parent !== null) {
      console.log(pdfRef);
      await deleteObject(pdfRef)
        .then(console.log("pdf viejo borrado"))
        .catch((err) => console.log(err));
    }
  }

  //function upload
  const uploadImage = () => {
    if (imageUpload !== null && imgChanged) {
      setBtnDisabled(true);
      document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
      const imageRef = ref(storage, `productos-imagenes/${imageUpload.name + v4()}`);
      console.log(imageRef._location.path_);
      productoEditable.ubicacionImg = imageRef._location.path_;
      uploadBytes(imageRef, imageUpload)
        .then((snapshot) => {
          borrarImgVieja();
          getDownloadURL(snapshot.ref).then((url) => {
            productoEditable.imgSrc = url;
            console.log("imagen subida");
            setImgChanged(false);
            uploadPdfFile();
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("error guardando imagen");
          setLabelBtnGuardar("Error ✕");
          setTimeout(() => {
            document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
            setLabelBtnGuardar("Guardar");
          }, 1000);
        });
    } else if (imageUpload === null && imgChanged) {
      console.log("imagen eliminada. No hay nueva.");
      productoEditable.ubicacionImg = "";
      productoEditable.imgSrc = "";
      borrarImgVieja();
      uploadPdfFile();
    }
  };

  //upload pdf file
  const uploadPdfFile = () => {
    if (pdfUpload !== null && pdfChanged) {
      setBtnDisabled(true);
      document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
      const pdfRef = ref(storage, `productos-pdf/${pdfUpload.name + v4()}`);
      productoEditable.ubicacionPdf = pdfRef._location.path_;
      uploadBytes(pdfRef, pdfUpload)
        .then((snapshot02) => {
          borrarPdfViejo();
          getDownloadURL(snapshot02.ref).then((url2) => {
            productoEditable.linkFichaProducto = url2;
            console.log("pdf subido");
            setPdfChanged(false);
            uploadProductData();
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("error guardando pdf");
          setLabelBtnGuardar("Error ✕");
          setTimeout(() => {
            document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
            setLabelBtnGuardar("Guardar");
          }, 1000);
        });
    } else if (pdfUpload === null && pdfChanged) {
      console.log("pdf eliminado. No hay nuevo.");
      productoEditable.ubicacionPdf = "";
      productoEditable.linkFichaProducto = "";
      borrarPdfViejo();
      uploadProductData();
    } else {
      console.log("sin pdf");
      uploadProductData();
    }
  };

  //upload to DataBase
  const uploadProductData = async () => {
    setBtnDisabled(true);
    document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
    const docModificable = doc(firestoreDb, `productos/${pathProducto}`);
    const productoRef = await setDoc(docModificable, productoEditable)
      .then(
        console.log("guardado en DB"),
        console.log(productoEditable),
        setLabelBtnGuardar("Guardado ✓"),
        queryForListasProductos(),
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(true);
          navigate("/listaproductos");
        }, 2000)
      )
      .catch((err) => {
        console.log(err);
        console.log("error guardando en Firestore");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(false);
        }, 1000);
      });
  };

  //function to set "destacado"
  function handleDestacado(e) {
    console.log(e.target.value);
    setBtnDisabled(false);
    if (e.target.value === "true") {
      productoEditable.destacado = true;
    } else {
      productoEditable.destacado = false;
    }
  }

  //function to set "categoria" of the product
  function hanldeCategorias(e) {
    console.log(e.target.value);
    setBtnDisabled(false);
    productoEditable.categoriaProducto = e.target.value;
  }

  //function to set pdf and image data after select file
  function completeImageData(e) {
    console.log(e.target.files);
    if (e.target.files.length !== 0) {
      setDisableBtnBorrarImg(false);
      setBtnDisabled(false);
      setImgChanged(true);
      setImageUpload(e.target.files[0]);
      setLabelFileUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
    }
  }
  function completePdfData(e) {
    console.log(e.target.files);
    if (e.target.files.length !== 0) {
      setDisableBtnBorrarPdf(false);
      setBtnDisabled(false);
      setPdfChanged(true);
      setLabelPdfUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
      setPdfUpload(e.target.files[0]);
    }
  }
  //functions counters and complete Name and Description
  function countCharName(e) {
    setBtnDisabled(false);
    let string = e.target.value;
    setCounterCharName(string.length);
    productoEditable.nombreProducto = e.target.value;
  }
  function countCharDesc(e) {
    setBtnDisabled(false);
    let string = e.target.value;
    setCounterCharDesc(string.length);
    productoEditable.descripcionProducto = e.target.value;
  }

  return (
    <div className="containerComponentes">
      <h2 className="tituloSeccion">Publicar nuevo producto</h2>
      <p className="alertTxt">Atención! Completar todos los campos obligatorios para poder guardar la publicación.</p>
      <br />
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
        }}
        id="formCargaProducto"
      >
        <div className="selectors">
          <span>
            <label htmlFor="selectCat" className="tituloSelectores">
              Categoría:
            </label>
            <select
              type="select "
              id="selectCat"
              className="selector"
              onChange={hanldeCategorias}
              defaultValue={`${productoEditable.categoriaProducto}`}
              key={productoEditable.categoriaProducto}
            >
              <option value="Leches en Polvo">Leches en Polvo</option>
              <option value="Bebidas Lácteas en Polvo">Bebidas Lácteas en Polvo</option>
              {/* <option value="Suplementos Deportivos">Suplementos Deportivos</option> */}
              <option value="Queso y Aderezos">Queso y Aderezos</option>
              <option value="Dulce de Leche y Pastas Dulces">Dulce de Leche y Pastas Dulces</option>
              <option value="Ingredientes Industriales">Ingredientes Industriales</option>
            </select>
          </span>
          <span className="selectDestacado">
            <p className="tituloSelectores">Destacado: </p>
            <select
              type="select"
              className="selector"
              onChange={handleDestacado}
              defaultValue={`${productoEditable.destacado}`}
              key={productoEditable.destacado}
            >
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </span>
        </div>
        <p className="titleInput">Título producto</p>
        <input type="text" className="inputTxt" maxLength={60} onChange={countCharName} defaultValue={productoEditable.nombreProducto} />
        <p className="counters">{counterCharName}/60</p>
        <p className="titleInput">Descripción producto</p>
        <textarea
          type="text"
          className="inputTxt areaTxt"
          maxLength={210}
          onChange={countCharDesc}
          style={{ height: "96px" }}
          defaultValue={productoEditable.descripcionProducto}
        />
        <p className="counters">{counterCharDesc}/210</p>

        <p className="titleInput">PDF ficha producto (opcional)</p>
        <label htmlFor="inputPDF" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input
            type="file"
            id="inputPDF"
            style={{ display: "none" }}
            maxLength={48}
            accept=".pdf"
            onChange={completePdfData}
            disabled={pdfUpload ? true : false}
          />
          <span id="imgName">{labelPdfUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarPdf && "classDisabled"}`}
            onClick={() => {
              setPdfUpload(null);
              setLabelPdfUpload("Seleccionar archivo (*.pdf)");
              setBtnDisabled(false);
              setPdfChanged(true);
              setDisableBtnBorrarPdf(true);
              document.getElementById("inputPDF").value = null;
            }}
          >
            ✕
          </button>
        </label>

        <p className="titleInput">Imágen producto (opcional)</p>
        <label htmlFor="inputImg" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input
            type="file"
            id="inputImg"
            style={{ display: "none" }}
            maxLength={48}
            accept="image/png"
            onChange={completeImageData}
            disabled={imageUpload ? true : false}
          />
          <span id="imgName">{labelFileUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarImg && "classDisabled"}`}
            onClick={() => {
              setImageUpload(null);
              setLabelFileUpload("Seleccionar archivo (*.png)");
              setBtnDisabled(false);
              setImgChanged(true);
              setDisableBtnBorrarImg(true);
              document.getElementById("inputImg").value = null;
            }}
          >
            ✕
          </button>
        </label>

        <span className="containerBtn">
          <Link to="/listaproductos" className="btnSubmit colorSec">
            Cancelar
          </Link>
          <button className="btnSubmit colorPpal " type="submit" onClick={guardarData} disabled={btnDisabled}>
            {labelBtnGuardar}
          </button>
        </span>
      </form>
    </div>
  );
}
