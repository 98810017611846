import React from "react";

//styles
import "../App.css";

//Router Dom
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

//components
import Header from "../components/Header";

export default function Principal() {
  return (
    <div className="App">
      <Header />
      <div className="mainContainer">
        <Outlet />
      </div>
    </div>
  );
}
