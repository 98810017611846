import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//contexto
import { AuthContext } from "../firebase";

//uuid package
import { v4 } from "uuid";

//firebase
import { storage, firestoreDb } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Timestamp, addDoc, collection } from "firebase/firestore";

//images
import FileUpload from "../assets/statics/uploadFile.svg";

//styles
import "../App.css";

export default function Productos() {
  const { queryForListasProductos } = useContext(AuthContext);

  //set variable that will be add to FB DataBase
  const [productoDB, setProductoDB] = useState({});

  useEffect(() => {
    setProductoDB({
      nombreProducto: "",
      descripcionProducto: "",
      linkFichaProducto: "",
      imgSrc: "",
      categoriaProducto: "Leches en Polvo",
      destacado: false,
      fechaCreacion: Timestamp.fromDate(new Date()),
      ubicacionPdf: "",
      ubicacionImg: "",
    });
  }, []);

  //btn states
  const [labelBtnGuardar, setLabelBtnGuardar] = useState("Guardar");
  const [btnDisabled, setBtnDisabled] = useState(true);

  //characters counters
  const [counterCharName, setCounterCharName] = useState(0);
  const [counterCharDesc, setCounterCharDesc] = useState(0);

  //file state
  const [imageUpload, setImageUpload] = useState(null);
  const [pdfUpload, setPdfUpload] = useState(null);
  const [labelFileUpload, setLabelFileUpload] = useState("Seleccionar archivo (*.png)");
  const [labelPdfUpload, setLabelPdfUpload] = useState("Seleccionar archivo (*.pdf)");

  //state for btn borrarContenido
  const [disableBtnBorrarImg, setDisableBtnBorrarImg] = useState(true);
  const [disableBtnBorrarPdf, setDisableBtnBorrarPdf] = useState(true);

  //Principal FUNCTION
  function guardarData(e) {
    e.preventDefault();
    setLabelBtnGuardar("Guardando...");
    uploadImage();
  }

  //function upload
  const uploadImage = () => {
    if (imageUpload !== null) {
      setBtnDisabled(true);
      document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
      const imageRef = ref(storage, `productos-imagenes/${imageUpload.name + v4()}`);
      console.log(imageRef._location.path_);
      productoDB.ubicacionImg = imageRef._location.path_;
      uploadBytes(imageRef, imageUpload)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            productoDB.imgSrc = url;
            console.log("imagen subida");
            uploadPdfFile();
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("error guardando imagen");
          setLabelBtnGuardar("Error ✕");
          setTimeout(() => {
            document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
            setLabelBtnGuardar("Guardar");
            setBtnDisabled(false);
          }, 1000);
        });
    } else if (imageUpload === null) {
      console.log("subir sin imagen");
      uploadPdfFile();
    }
  };

  //upload pdf file
  const uploadPdfFile = () => {
    if (pdfUpload !== null) {
      setBtnDisabled(true);
      document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
      const pdfRef = ref(storage, `productos-pdf/${pdfUpload.name + v4()}`);
      productoDB.ubicacionPdf = pdfRef._location.path_;
      uploadBytes(pdfRef, pdfUpload)
        .then((snapshot02) => {
          getDownloadURL(snapshot02.ref).then((url2) => {
            productoDB.linkFichaProducto = url2;
            console.log("pdf subido");
            uploadProductData();
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("error guardando pdf");
          setLabelBtnGuardar("Error ✕");
          setTimeout(() => {
            document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
            setLabelBtnGuardar("Guardar");
            setBtnDisabled(false);
          }, 1000);
        });
    } else {
      setBtnDisabled(true);
      document.body.setAttribute("style", "pointer-events: none; opacity: 0.5;");
      console.log("sin pdf");
      uploadProductData();
    }
  };

  //upload to DataBase
  const uploadProductData = async () => {
    console.log(productoDB);
    const productoRef = await addDoc(collection(firestoreDb, "productos"), productoDB)
      .then(
        console.log("guardado en DB"),
        console.log(productoDB),
        setLabelBtnGuardar("Guardado ✓"),
        queryForListasProductos(),
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          document.getElementById("formCargaProducto").reset();
          setPdfUpload(null);
          setImageUpload(null);
          setLabelFileUpload("Seleccionar archivo (*.png)");
          setLabelPdfUpload("Seleccionar archivo (*.pdf)");
          setBtnDisabled(true);
          setProductoDB({
            nombreProducto: "",
            descripcionProducto: "",
            linkFichaProducto: "",
            imgSrc: "",
            categoriaProducto: "Leches en Polvo",
            destacado: false,
            fechaCreacion: Timestamp.fromDate(new Date()),
            ubicacionPdf: "",
            ubicacionImg: "",
          });
        }, 2000)
      )
      .catch((err) => {
        console.log(err);
        console.log("error guardando en Firestore");
        setLabelBtnGuardar("Error ✕");
        setTimeout(() => {
          document.body.setAttribute("style", "pointer-events: all; opacity: 1;");
          setLabelBtnGuardar("Guardar");
          setBtnDisabled(false);
        }, 1000);
      });
  };

  //Enable "Guardar" btn only if every field is complete
  document.addEventListener("keyup", enableBtn);
  document.addEventListener("change", enableBtn);

  function enableBtn(e) {
    e.preventDefault();
    if (productoDB.nombreProducto !== "" && productoDB.descripcionProducto !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  //function to set "destacado"
  function handleDestacado(e) {
    console.log(e.target.value);
    if (e.target.value === "true") {
      productoDB.destacado = true;
    } else {
      productoDB.destacado = false;
    }
  }

  //function to set "categoria" of the product
  function hanldeCategorias(e) {
    productoDB.categoriaProducto = e.target.value;
  }

  //function to set pdf and image data after select file
  function completeImageData(e) {
    if (e.target.files.length !== 0) {
      console.log(e.target.files[0]);
      setDisableBtnBorrarImg(false);
      setImageUpload(e.target.files[0]);
      setLabelFileUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
    }
  }
  function completePdfData(e) {
    if (e.target.files.length !== 0) {
      console.log(e.target.files[0]);
      setDisableBtnBorrarPdf(false);
      setLabelPdfUpload(e.target.files[0].name + "- (" + e.target.files[0].size + "bytes)");
      setPdfUpload(e.target.files[0]);
    }
  }
  //functions counters and complete Name and Description
  function countCharName(e) {
    let string = e.target.value;
    setCounterCharName(string.length);
    productoDB.nombreProducto = e.target.value;
  }
  function countCharDesc(e) {
    let string = e.target.value;
    setCounterCharDesc(string.length);
    productoDB.descripcionProducto = e.target.value;
  }

  return (
    <div className="containerComponentes">
      <h2 className="tituloSeccion">Publicar nuevo producto</h2>
      <p className="alertTxt">Atención! Completar todos los campos obligatorios para poder guardar la publicación.</p>
      <br />
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault();
        }}
        id="formCargaProducto"
      >
        <div className="selectors">
          <span>
            <label htmlFor="selectCat" className="tituloSelectores">
              Categoría:
            </label>
            <select type="select" id="selectCat" className="selector" onChange={hanldeCategorias}>
              <option value="Leches en Polvo">Leches en Polvo</option>
              <option value="Bebidas Lácteas en Polvo">Bebidas Lácteas en Polvo</option>
              {/* <option value="Suplementos Deportivos">Suplementos Deportivos</option> */}
              <option value="Queso y Aderezos">Queso y Aderezos</option>
              <option value="Dulce de Leche y Pastas Dulces">Dulce de Leche y Pastas Dulces</option>
              <option value="Ingredientes Industriales">Ingredientes Industriales</option>
            </select>
          </span>
          <span className="selectDestacado">
            <p className="tituloSelectores">Destacado:</p>
            <select type="select" className="selector" onChange={handleDestacado} defaultValue="false">
              <option value="true">Si</option>
              <option value="false">No</option>
            </select>
          </span>
        </div>
        <p className="titleInput">Título producto</p>
        <input type="text" className="inputTxt" maxLength={60} onChange={countCharName} />
        <p className="counters">{counterCharName}/60</p>
        <p className="titleInput">Descripción producto</p>
        <textarea type="text" className="inputTxt areaTxt" maxLength={210} onChange={countCharDesc} style={{ height: "96px" }} />
        <p className="counters">{counterCharDesc}/210</p>

        <p className="titleInput">PDF ficha producto (opcional)</p>
        <label htmlFor="inputPDF" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input
            type="file"
            id="inputPDF"
            style={{ display: "none" }}
            maxLength={48}
            accept=".pdf"
            onChange={completePdfData}
            disabled={pdfUpload ? true : false}
          />
          <span id="imgName">{labelPdfUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarPdf && "classDisabled"}`}
            onClick={() => {
              setPdfUpload(null);
              setLabelPdfUpload("Seleccionar archivo (*.pdf)");
              setDisableBtnBorrarPdf(true);
              document.getElementById("inputPDF").value = null;
            }}
          >
            ✕
          </button>
        </label>

        <p className="titleInput">Imágen producto (opcional)</p>
        <label htmlFor="inputImg" className="fileImput">
          <img src={FileUpload} alt="upload file" title="Seleccionar archivo" className="icon" />
          <input
            type="file"
            id="inputImg"
            style={{ display: "none" }}
            maxLength={48}
            accept="image/png"
            onChange={completeImageData}
            disabled={imageUpload ? true : false}
          />
          <span id="imgName">{labelFileUpload}</span>{" "}
          <button
            className={`borrarContenido ${disableBtnBorrarImg && "classDisabled"}`}
            onClick={() => {
              setImageUpload(null);
              setLabelFileUpload("Seleccionar archivo (*.png)");
              setDisableBtnBorrarImg(true);
              document.getElementById("inputImg").value = null;
            }}
          >
            ✕
          </button>
        </label>
        <span className="containerBtn">
          <Link to="/listaproductos" className="btnSubmit colorSec">
            Cancelar
          </Link>
          <button className="btnSubmit colorPpal " type="submit" onClick={guardarData} disabled={btnDisabled}>
            {labelBtnGuardar}
          </button>
        </span>
      </form>
    </div>
  );
}
